<template>
  <async-container :loading="!loaded">
    <v-card max-width="800" class="mx-auto">
      <v-card-title>Compromisso</v-card-title>
      <v-card-text>
        <v-form ref="form-event" @submit.prevent="salvarEvento" :disabled="saving">
          <!-- Titulo -->
          <v-text-field
            label="Título"
            placeholder="Escreva o nome do compromisso"
            :rules="[rules.obrigatorio]"
            v-model="iptTitulo"
          ></v-text-field>
          <!-- Cor -->
          <div class="d-flex align-center">
            <v-autocomplete
              class="mt-2"
              label="Cor do compromisso"
              :items="cores"
              placeholder="Selecione a cor de acordo com a categoria"
              hint="Selecione a cor de acordo com a categoria"
              outlined
              dense
              :rules="[rules.obrigatorio]"
              v-model="iptCor"
              no-data-text="Nenhuma cor encontrada, use o botão ao lado para criar"
              item-value="id"
              item-text="nome"
            >
              <template v-slot:selection="context">
                <v-chip v-if="context && context.item" label :color="context.item.valor" small class="white--text">{{context.item.nome}}</v-chip>
              </template>
              <template v-slot:item="{item}">
                <v-chip label :color="item.valor" small class="white--text">{{item.nome}}</v-chip>
              </template>
            </v-autocomplete>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="warning" icon class="mb-4" v-on="on" v-bind="attrs" @click="dialogCores = true">
                  <v-icon>mdi-book-edit</v-icon>
                </v-btn>
              </template>
              <span>Gerenciar cadastro de cores</span>
            </v-tooltip>
          </div>
          <!-- Descricao/Texto -->
          <v-textarea
            :rows="4"
            v-model="iptTexto"
            label="Informações complementares"
            placeholder="Descrição do compromisso... [opcional]"
            outlined
            maxlength="255"
            counter
          ></v-textarea>
          <!-- Publico -->
          <v-switch
            class="mt-1"
            label="Público"
            hint="Define se o compromisso deve ser exibido no calendário público do CRECI-MS"
            persistent-hint
            v-model="iptPublico"
          ></v-switch>
          <v-divider class="my-3"></v-divider>
          <!-- Datetime Inicio -->
          <div>
            <p class="subtitle-2 mb-0">Quando</p>
            <v-row>
              <v-col cols="12" md="6" class="pb-md-0">
                <v-dialog
                  ref="dialog-dateinicio"
                  v-model="dialogDateInicio"
                  :return-value.sync="iptDateInicio"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      placeholder="Clique para escolher a data"
                      :value="txtDateInicio"
                      label="Data do compromisso"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[rules.obrigatorio]"
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="iptDateInicio"
                    scrollable
                    :max="iptDateFim"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="dialogDateInicio = false"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs['dialog-dateinicio'].save(iptDateInicio)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" md="6" class="pb-md-0">
                <v-dialog
                  ref="dialog-timeinicio"
                  v-model="dialogTimeInicio"
                  :return-value.sync="iptTimeInicio"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :placeholder="iptDateInicio ? 'Clique para escolher o horário' : 'Escolha a data primeiro'"
                      v-model="iptTimeInicio"
                      label="Hora do compromisso"
                      prepend-inner-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[rules.obrigatorio]"
                      outlined
                      dense
                      :disabled="!iptDateInicio"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="dialogTimeInicio"
                    v-model="iptTimeInicio"
                    full-width
                    format="24hr"
                    :max="iptDateInicio && iptDateFim && iptDateInicio === iptDateFim && iptTimeFim !== '00:00' ? iptTimeFim : undefined"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="dialogTimeInicio = false"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs['dialog-timeinicio'].save(iptTimeInicio)"
                    >
                      OK
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </div>
          <v-divider class="mb-3"></v-divider>
          <!-- Datetime Fim -->
          <div>
            <p class="subtitle-2 mb-0">Até quando</p>
            <p class="caption red--text">Opcional</p>
            <v-row>
              <v-col cols="12" md="6" class="pb-md-0">
                <v-dialog
                  ref="dialog-datefim"
                  v-model="dialogDateFim"
                  :return-value.sync="iptDateFim"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      placeholder="Opcional. Clique para definir"
                      :value="txtDateFim"
                      label="Data de encerramento"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      clearable
                      @click:clear="iptDateFim = ''"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="iptDateFim"
                    scrollable
                    :min="iptDateInicio"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="dialogDateFim = false"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs['dialog-datefim'].save(iptDateFim)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" md="6" class="pb-md-0">
                <v-dialog
                  ref="dialog-timefim"
                  v-model="dialogTimeFim"
                  :return-value.sync="iptTimeFim"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :placeholder="iptDateFim ? 'Clique para escolher o horário' : 'Escolha a data primeiro'"
                      v-model="iptTimeFim"
                      label="Hora do encerramento"
                      prepend-inner-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      clearable
                      @click:clear="iptDateFim = ''"
                      :disabled="!iptDateFim"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="dialogTimeFim"
                    v-model="iptTimeFim"
                    full-width
                    format="24hr"
                    :min="iptDateInicio && iptDateFim && iptDateInicio === iptDateFim ? iptTimeInicio : undefined"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="dialogTimeFim = false"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs['dialog-timefim'].save(iptTimeFim)"
                    >
                      OK
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </div>
          <v-divider class="mb-3"></v-divider>
          <!-- Reserva de local -->
          <div>
            <p class="subtitle-2 mb-0">Local do compromisso</p>
            <p class="caption">Escolha uma sala para garantir a reserva para o seu compromisso</p>
            <v-select
              label="Sala"
              :items="iptSalas"
              v-model="iptSala"
              hint="A sala escolhida ficará protegida de agendamentos no mesmo horário"
              :persistent-hint="iptSala !== null"
              outlined
              dense
            ></v-select>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center" style="border-top: 1px solid #dbdbdb">
        <v-btn color="success" @click="salvarEvento" :loading="saving">Salvar</v-btn>
        <v-btn color="secondary" to="/calendario" :disabled="saving">Sair</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialogCores" width="360" scrollable>
      <v-card :loading="loadingDelete">
        <v-card-title style="border-bottom: 1px solid #dbdbdb">
          Cores
          <v-spacer/>
          <v-icon @click="dialogCores = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text style="height: 300px" class="pa-0">
          <v-list dense class="py-0">
            <template v-for="(c, i) in cores">
              <v-list-item :key="c.id" class="hover-item">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-chip :color="c.valor" x-small label class="white--text">{{c.nome}}</v-chip>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{c.eventos === 0 ? 'Sem uso no momento' : (c.eventos > 1 ? `Utilizado em ${c.eventos} eventos` : 'Utilizado em 1 evento')}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="error" :disabled="loadingDelete" @click="deletarCor(c.id)">mdi-delete</v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-divider v-if="cores.length !== (i + 1)" :key="c.id + '_divider'"></v-divider>
            </template>
            <p v-if="cores.length === 0" class="text-center mt-3 px-2">Nenhuma cor no sistema. Crie a primeira cor.</p>
          </v-list>
        </v-card-text>
        <v-card-actions class="justify-center" style="border-top: 1px solid #dbdbdb">
          <v-btn color="success" @click="() => {dialogCores = false; dialogAddCor = true;}" :disabled="loadingDelete">CRIAR UMA COR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAddCor" max-width="400">
      <v-card>
        <v-card-title>
          Criando nova cor
          <v-spacer/>
          <v-icon @click="dialogAddCor = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form ref="form-addcor" @submit.prevent="salvarCor">
            <v-text-field
              dense
              label="Titulo desta cor"
              persistent-hint
              hint="Exemplo: Reunião de diretoria"
              placeholder="Atribua um nome"
              outlined
              v-model="iptCorNome"
              :rules="[rules.obrigatorio]"
            ></v-text-field>
          </v-form>
          <div class="d-flex justify-center mt-2">
            <v-color-picker
              ref="color-picker"
              mode="hexa"
              value="#1EBC4B"
            ></v-color-picker>
          </div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="salvarCor" :loading="loadingSavingNewColor">SALVAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </async-container>
</template>

<script>
import AsyncContainer from '@/components/interface/AsyncContainer';
import moment from 'moment';
import SiteWebClient from '@/http/SiteWebClient';
export default {
  name: 'EventEditor',
  props: ['id'],
  components: {AsyncContainer},
  data: () => ({
    loaded: false,
    rules: {
      obrigatorio: v => !!v || 'Este campo é obrigatório',
    },
    cores: [],
    dialogDateInicio: false,
    dialogTimeInicio: false,
    dialogDateFim: false,
    dialogTimeFim: false,
    iptTitulo: '',
    iptTexto: '',
    iptCor: null,
    iptPublico: true,
    iptDateInicio: '',
    iptTimeInicio: '',
    iptDateFim: '',
    iptTimeFim: '',
    iptSala: null,
    iptSalas: [
      {text: 'Nenhuma', value: null},
      {text: 'Sala de reunião', value: 1},
      {text: 'Auditório do CRECI-MS', value: 2},
    ],
    dialogCores: false,
    dialogAddCor: false,
    webClient: new SiteWebClient(),
    loadingDelete: false,
    loadingSavingNewColor: false,
    iptCorNome: '',
    saving: false,
  }),
  computed: {
    txtDateInicio() {
      return this.iptDateInicio ? moment(this.iptDateInicio).format('DD/MM/YYYY') : '';
    },
    txtDateFim() {
      return this.iptDateFim ? moment(this.iptDateFim).format('DD/MM/YYYY') : '';
    },
  },
  methods: {
    async loadData() {
      if (!this.id) return;
      const evento = await this.webClient.getCalendarioEvento(this.id);
      this.iptTitulo = evento.nome;
      this.iptTexto = evento.texto ? evento.texto : '';
      this.iptDateInicio = evento.inicio.substr(0, 10);
      this.iptTimeInicio = evento.inicio.substr(11, 5);
      this.iptPublico = evento.publico;
      this.iptCor = evento.cor_id;
      this.iptSala = evento.sala;
      if (evento.fim) {
        this.iptDateFim = evento.fim.substr(0, 10);
        this.iptTimeFim = evento.fim.substr(11, 5);
      }
      this.loaded = true;
    },
    async salvarEvento() {
      if (!this.$refs['form-event'].validate()) return;
      if ((this.iptDateFim && !this.iptTimeFim) || (!this.iptDateFim && this.iptTimeFim)) {
        this.$alert({text: 'Se deseja utilizar a data de encerramento, você precisa preencher ambos (data e hora), ou então deixe-os vazio', type: 'warning'});
        return;
      }
      this.saving = true;
      try {
        const dateInicio = this.iptDateInicio + ' ' + this.iptTimeInicio + ':00';
        const dateFim = (this.iptDateFim && this.iptTimeFim) ? this.iptDateFim + ' ' + this.iptTimeFim + ':00' : null;
        if (this.id) {
          await this.webClient.updateCalendarioEvento(this.id, this.iptTitulo, this.iptCor, this.iptTexto, dateInicio, dateFim, this.iptPublico, this.iptSala);
          this.$snackbar({text: 'Compromisso atualizado', color: 'success', timeout: 2500});
        } else {
          await this.webClient.insertCalendarioEvento(this.iptTitulo, this.iptCor, this.iptTexto, dateInicio, dateFim, this.iptPublico, this.iptSala);
          this.$snackbar({text: 'Compromisso registrado', color: 'success', timeout: 2500});
        }
        this.$router.push('/calendario');
      } finally {
        this.saving = false;
      }
    },
    async deletarCor(id) {
      const cor = this.cores.find(i => i.id === id);
      if (cor.eventos !== 0) {
        this.dialogCores = false;
        this.$alert({
          text: 'Você não pode apagar uma cor que está em uso. Remova os compromissos primeiro.',
          type: 'error'
        });
        return;
      }
      if (!window.confirm(`Tem certeza que deseja apagar a cor "${cor.nome}"?`)) return;
      this.loadingDelete = true;
      try {
        await this.webClient.deleteCalendarioCor(id);
        this.cores = await this.webClient.getCalendarioCores();
      } finally {
        this.loadingDelete = false;
        this.dialogCores = false;
      }
    },
    async salvarCor() {
      if (!this.$refs['form-addcor'].validate()) return;
      this.loadingSavingNewColor = true;
      try {
        await this.webClient.insertCalendarioCor(this.iptCorNome, this.$refs['color-picker'].internalValue.hex);
        this.cores = await this.webClient.getCalendarioCores();
        this.dialogAddCor = false;
        this.iptCorNome = '';
      } finally {
        this.loadingSavingNewColor = false;
      }
    },
  },
  async created() {
    try {
      this.cores = await this.webClient.getCalendarioCores();
      if (this.id) await this.loadData();
      else this.loaded = true;
    } catch (e) {
      this.$router.push('/calendario');
    }
  },
  watch: {
    iptDateInicio() {
      if (!this.iptTimeInicio) this.iptTimeInicio = '00:00';
    },
    iptDateFim() {
      if (!this.iptTimeFim) this.iptTimeFim = '00:00';
    },
  },
}
</script>

<style scoped>
  .hover-item:hover {
    background-color: rgba(0,0,0,.1);
  }
</style>